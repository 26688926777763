import { Button, Card } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import "./SummaryChatbot.css";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { jsPDF } from 'jspdf';



function SummaryChatbot({ setChatHistory,chatHistory, fileContent, fileName }) {



  const [chatMessages, setChatMessages] = useState([]);
  
  const [userMessage, setUserMessage] = useState();

  const chatboxRef = useRef(null);
  const chatInputRef = useRef(null);
  const cardRef = useRef(null);
  
  const headerRef = useRef(null);
  const [isLoading, setIsloading] = useState(false);
  const [response, setResponse] = useState();
  const [chatInput, setChatInput] = useState('');


  // useEffect(() => {
  //   if (chatboxRef.current) {
  //     const { scrollHeight, clientHeight } = chatboxRef.current;
  //     const scrollPosition = scrollHeight - clientHeight - cardRef?.current?.clientHeight + 200; 
  //     chatboxRef.current.scrollTo(0, scrollPosition);
  //   }
  // }, [chatHistory]);
  useEffect(() => {
    console.log("it runs",chatboxRef.current)
    let upscroll = 0;
    if (cardRef.current && headerRef.current){
      upscroll = cardRef.current.clientHeight + headerRef.current.clientHeight;
      console.log("Cardref:",cardRef.current.clientHeight);
    }
    if (chatboxRef.current) {
      const { scrollHeight, clientHeight } = chatboxRef.current;
      const scrollPosition = scrollHeight;
      chatboxRef.current.scrollTo({
        top: scrollPosition - upscroll,
        behavior: 'smooth',
      });
      console.log("it runs scrollPosition",scrollPosition, clientHeight)
    }
  }, [chatHistory]);


  const handleInputChange = (event) => {
    setChatInput(event.target.value);
    setUserMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      apicall();
    }
  };

  const handleChatDownload =() =>{
    console.log("download Hits", chatHistory);
    const doc = new jsPDF();

    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2 - 20;

    doc.setFontSize(12);
    doc.text('CHAT', margin, 10);
    let currentY = 20; // Start position for the first entry


    // Add each object to the PDF
    chatHistory.forEach((item, index) => {
      if(item.role && item.role!==""){

      // Check for page break before adding new content
      if (currentY + 20 > pageHeight) { // 20 is for the title height and spacing
        doc.addPage();
        currentY = 10; // Reset Y position for the new page
        doc.text('User Information', margin, currentY);
        currentY += 10; // Adjust after the title on the new page
      }

      // Make role bold
      doc.setFont('helvetica', 'bold');
      const roleText = `${item.role}:`;
      doc.text(roleText, margin, currentY);

      // Reset font to normal for content
      doc.setFont('helvetica', 'normal');
      const details = `${item.content}`;

      // Shift details to the right
      const detailsX = margin + 20; // Adjust this value to shift right

      // Split and get the lines for details
      const detailsLines = doc.splitTextToSize(details, maxLineWidth);

      // Add details text, adjusting Y position
      detailsLines.forEach((line, lineIndex) => {
        if (currentY + 6 + (lineIndex * 10) > pageHeight) { // Check if it fits on the current page
          doc.addPage();
          currentY = 10; // Reset Y position for the new page
          doc.text('User Information', margin, currentY);
          currentY += 10; // Adjust after the title on the new page
        }
        doc.text(line, detailsX, currentY + 6 + (lineIndex * 10)); // 10 is line height
      });

      // Update currentY to the end of the last detail line
      currentY += 6 + (detailsLines.length * 10) + 10; // Adjust for padding between entries
     
        // let text = `: `

        // doc.text(doc.splitTextToSize(text, maxLineWidth), margin, 20 + (index * 20));
      
      }
    });
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();

    // Save the generated PDF
    doc.save(`${fileName}_chatlog_${currDate}_${currTime}.pdf`);
  }





  const apicall = async () => {
    const API_URL = 'https://pdfing.ai:7000/pdfchat';
    setUserMessage("");
    setChatHistory([...chatHistory, { "role": "user", "content": chatInput }])
    console.log("FileContent:-----",fileContent)

    if (!chatInput) {
      return;
    }


    try {
      setIsloading(true);
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          "query": chatInput,
          "context": fileContent,
          "history": chatHistory ? chatHistory : []

        }),
      };



      const response = await fetch(API_URL, requestOptions);
      const data = await response.json();
      setResponse(data);
      setChatHistory([...chatHistory, { "role": "user", "content": chatInput }, data])
      setIsloading(false);

    } catch (e) {

      console.log("some error occured");
      setIsloading(false);

    }

  }

  console.log("chat history", chatHistory)

  return (
    <div>

      <div className="chatbot" style={{ backgroundColor: 'white', width: "35%" }}>

        <header ref={headerRef} style={{ backgroundColor: '#0066cc' }}>
          <h2>Chat with {fileName ? fileName : "document"}</h2>
          <span className="close-btn material-symbols-outlined" onClick={() => document.body.classList.remove('show-chatbot')}>
            close
          </span>
        </header>

        <ul className="chatbox" ref={chatboxRef}>
          {chatHistory.length === 1 && (
            <li className="chat incoming">
              <p>
                Welcome to <u style={{ color: 'red' }}>Pdfing.ai</u> You can chat with the selected pdf here.
                <br />
                <br />

                {!fileName && " Please upload a pdf to start chatting."}

              </p>

            </li>
          )}




          {chatHistory.length > 0 && chatHistory.map((message, index) => (
            message.content != "" &&(
              <li key={index} className={`chat ${message.role}`}>
              {message.role === 'user' ? (
                <div style={{
                  maxWidth: "55%",
                  padding: "10px",
                  borderRadius: "12px", backgroundColor: "#D7BDE2"
                }}>{message.content}</div>
              ) : (
                message &&
                <>
                  <span style={{
                    color: "white",
                    backgroundColor: "#2980B9",
                    padding: "5px",
                    width: "fit-content",
                    height: "auto",
                    textAlign: "center",
                  }}>PDFing</span>


                  <Card 
                    ref={cardRef}
                    sx={{
                      width: "75%", marginTop: 2, backgroundColor: "#D6EAF8",
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: 8, padding: 3
                    }}>
                    {message.content}
                  </Card>
                </>
              )}
            </li>
            )
          ))}
        </ul>


        <div className="chat-input">

          {isLoading && <div style={{ marginLeft: "30px" }} >Loading...</div>

          }


          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginLeft: "10px" }}>
            <div style={{
              position:"absolute",
              right:"10px",
              bottom:"90px",
              backgroundColor:"#257cda",
              display:"flex",
           
              alignItems:"center",
              justifyContent:"center",
              color:"#fff",
              height:"40px",
              width:"40px",
              borderRadius:"60%",
              cursor:"pointer"
            }} onClick={handleChatDownload}>
              <DownloadOutlinedIcon />
            </div>




          </div>

          <div style={{ display: "flex", borderTop: "1px solid #ddd" }}>

            <textarea
              value={userMessage}
              placeholder="Enter a message..."
              spellCheck={false}
              ref={chatInputRef}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            ></textarea>

            <span id="send-btn" className="material-symbols-rounded" onClick={apicall}>
              <i style={{ fontSize: "25px" }} class="ri-arrow-right-circle-line"></i>
            </span>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SummaryChatbot
