import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from '@auth0/auth0-react';

// import { GoogleOAuthProvider } from '@react-oauth/google';
// import config from './GoogleAuthCredential/config';
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));


// root.render(
//   <Auth0Provider
//     domain="verisage2024.us.auth0.com"
//     clientId="TymYznM5Yx1uCqzQKs8u8RG9s7eaCsWK"
//     redirectUri={window.location.origin}
//   >
//   <App />
//    </Auth0Provider>,
// );

root.render(
  <Auth0Provider
    domain="verisage2024.us.auth0.com"
    clientId="TymYznM5Yx1uCqzQKs8u8RG9s7eaCsWK"
    //domain="dev-rheadgwgt1mudjub.us.auth0.com"
    // clientId="dFejoxo7QvMzOxT4m0wIsShzgNJK5dx7"
    redirectUri={window.location.origin}
  >
    <App />
  </Auth0Provider>,
);


// root.render(
//   // <Auth0Provider
//   //   domain="YOUR_AUTH0_DOMAIN"
//   //   clientId="YOUR_AUTH0_CLIENT_ID"
//   //   redirectUri={window.location.origin}
//   // >
//   <GoogleOAuthProvider clientId={config.clientId}>
//   <App />
//   </GoogleOAuthProvider>

//   // </Auth0Provider>,
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
