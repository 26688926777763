import React from "react";
import styles from "./Testimonial.module.css";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className={styles.container}>
        <div className="section-title text-center">
          <h2 className={styles.headLine}>What our clients say</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className={styles.contentContainer}>
                    <div className={styles.imageStyles}>
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className={styles.textTestimonial}>
                      <p>"{d.text}"</p>
                      <div className={styles.testMeta}> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
