import { useEffect, useState } from "react";
import styles from "./SummaryContainer.module.css";
import svg from "../../../assets/svg_Mesa de trabajo 1 copia 9.svg";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function SummaryContainer({ file, setFile }) {
  const { logout, isAuthenticated, loginWithRedirect, loginWithPopup } = useAuth0();
  const [toggle, setToggle] = useState("paragraph");

  const [value, setValue] = useState(50);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const smallTabStyle = {
    paddingLeft: "5px",
    paddingRight: "5px",
    Margin: "10px",
    borderRadius: "20px",
  };

  const handleSliderChange = (e) => {
    setValue(e.target.value);
  };

  function handleToggle(value) {
    setToggle(value);
  }

  function handleFileUpload3(e) {
    e.preventDefault();
    setLoading(true);
    setFile(e.target.files[0]);
    console.log("homepage");
  }

  useEffect(() => {
    if (file) {
      setLoading(false);
      navigate("/summariser");
    }
  }, [file]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.textContainer}>
        <div>
          <h2 className={styles.sumaryText}>Summarize Your Files</h2>
        </div>
        <div className={styles.summaryPara}></div>
      </div>
      <div className={styles.uploadbox}>
        <div className={styles.boxContent}>
          <div>
            <label
              htmlFor="file-upload"
              className={styles.fileLabel}
              onClick={() => {
                // !isAuthenticated && loginWithRedirect();
                !isAuthenticated && loginWithPopup();
              }}
            >
              <div className={styles.uploadIcon}></div>
              {/* <img className={styles.svgCloudBttom} src={svg}/> */}
              <span className={styles.textFile}>SELECT FILE</span>
              <span>Add PDFS, WORD files</span>

              {isAuthenticated && (
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileUpload3}
                  className={styles.fileInput}
                  id="file-upload"
                />
              )}

              <div
                style={{
                  fontSize: "12px",
                  color: "#555",
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                Supported format:{" "}
                <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }}>
                  PDF
                </div>
                <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }}>
                  Docx
                </div>
              </div>
            </label>
            {loading && <h2>Loading ...</h2>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryContainer;
