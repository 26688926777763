import React, { useEffect, useRef, useState } from "react";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import pdfToText from "react-pdftotext";
import "./AdvancedSummary.css";
import { Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import ShareLinkComponent from "./ShareLinkComponent";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import GearGif from "../../../GearGif.gif";
import { background } from "@chakra-ui/react";
import { Margin } from "@mui/icons-material";
import UploadCloud from "../../../UploadCloud.png";
import styles from "./FileUpload.module.css";

// import htmlToDocx from 'html-to-docx';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';



import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const smallTabStyle = {
  paddingLeft: "5px",
  paddingRight: "5px",
  Margin: "10px",
  borderRadius: "20px",
}

function FileUploadComponent({ setWordsCount, type1Value, type2Value, urlStats, response, file, setFile, fileContent, setFileContent, isLoading, ocrProcessing,
  setIsLoading, setUploadedfile, setShowSummaryChatbot,
  callOverviewOnce, fileName, setFileName }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdown, setdropdown] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [fileName,setFileName] = useState(null);
  const inputRef = useRef(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setdropdown(null)
  };
  const handleMenuClick = (event, index) => {

    setAnchorEl(event.currentTarget);
    setdropdown(!dropdown)
  };






  const handleFileUpload2 = (event) => {
    console.log("fileconten44", fileContent)
    const file = event.target.files[0];
    setFileName(file.name);
    setFile(event.target.files[0])
    console.log(event.target.files[0])
    setIsLoading(true)
    setUploadedfile(file);
    if (!file) return;
  };

  useEffect(() => {
    if (file) {
      setShowSummaryChatbot(false)
      setFileName(file.name);
      setUploadedfile(file);
      const reader = new FileReader();

      reader.onload = async (e) => {
        const content = e.target.result;

        if (file.type === "application/pdf") {
          // For PDF files

          if (ocrProcessing){
            console.log("ocr processing call:")
            await handleOcrCall(file)


          }
          else{
            console.log("ocr is off---")
          const loadingTask = pdfjs.getDocument({ data: content });
          const pdf = await loadingTask.promise;

          let pageTexts = "";

          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);
            const textContent = await page.getTextContent();
            const pageText = textContent.items.map(item => item.str).join(' ');

            pageTexts = pageTexts + (`Page ${pageNumber}:\n${pageText}`);
          }

          console.log("Split pages text: ", pageTexts);
          // pageTexts = pageTexts
          callOverviewOnce(pageTexts);
          setFileContent(pageTexts);  // Set the content split by pages

          // const text = await pdfToText(file);
          setWordsCount(pageTexts.trim().split(/\s+/).length)
          // callOverviewOnce(text);
          // setFileContent(text);
          // setIsLoading(false)
          // }


        }
        } else if (
          file.type === "application/msword" ||
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // For Word document files
          const doc = new Docxtemplater(new PizZip(content), {
            delimiters: {
              start: "12op1j2po1j2poj1po",
              end: "op21j4po21jp4oj1op24j",
            },
          });
          const parsedContent = doc.getFullText();
          setWordsCount(parsedContent.trim().split(/\s+/).length)
          console.log("doc file 22")
          console.log(parsedContent)
          setFileContent(parsedContent);
          callOverviewOnce(parsedContent);
        } else {
          setFileContent("");
          setErrorMessage(
            "Unsupported file format. Please upload a PDF or Document file."
          );
        }
      };

      reader.readAsArrayBuffer(file);
    }
  }, [file]);



  const handleOcrCall = async (uploadedFile) => {
    console.log("CallinfOCR", uploadedFile)
    const apiLink = "https://pdfing.ai:7000/ocr";
    const formData = new FormData();
    formData.append('pdfFile', uploadedFile);


    const requestOptions = {
      method: "POST",
      body: formData,
    };



    try {
      setIsLoading(true);
      let ocrResponse = await fetch(apiLink, requestOptions);
      let parsedOcrResponse = await ocrResponse.json();
      // setOcrResponse(parsedOcrResponse);
      console.log("ocr response: ",parsedOcrResponse.content)
      setFileContent(parsedOcrResponse.content)
      setWordsCount(parsedOcrResponse.content.trim().split(/\s+/).length)

      callOverviewOnce(parsedOcrResponse.content)


      setIsLoading(false)
      console.log("ocr response", parsedOcrResponse)

    } catch (error) {
      console.log("error", error)
      setIsLoading(false)
    }
  };




  const handleDownloadPDF = async () => {
    setdropdown(false);

    const input = inputRef;
    console.log(input);
    console.log(input.current);

    // const canvas = await html2canvas(input);
    // const imgData = canvas.toDataURL('image/png');
    // const pdf = new jsPDF();
    // const imgWidth = 210;
    // const imgHeight = (canvas.height * imgWidth) / canvas.width;
    // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    // pdf.save(`${fileName}_summary_${type1Value}_${type2Value}.pdf`);
  };

  // const handleDownloadWord = async () => {
  //   setdropdown(false);

  //   const input = inputRef.current; // Your HTML content

  //   // Simple text extraction (adjust this as per your needs)
  //   const htmlContent = input.innerHTML; // Extract innerHTML

  //   // Create a new Document
  //   const doc = new Document({
  //     sections: [
  //       {
  //         children: [
  //           new Paragraph({
  //             children: [new TextRun(htmlContent)],
  //           }),
  //         ],
  //       },
  //     ],
  //   });

  //   // Generate the document as a Blob
  //   const blob = await Packer.toBlob(doc);

  //   // Save the file
  //   saveAs(blob, `${fileName}_summary_${type1Value}_${type2Value}.docx`);
  // };


  const wrapHtmlContent = (htmlContent) => {
    return `
        <!DOCTYPE html>
        <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        <head><meta charset='utf-8'><title>Document</title></head><body>${htmlContent}</body></html>
      `;
  };

  const handleDownloadDOC = (response, filename) => {
    setdropdown(false);

    const wrappedHtml = wrapHtmlContent(response);
    const blob = new Blob(['\ufeff', wrappedHtml], {
      type: 'application/msword',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}_summary_${type1Value}_${type2Value}.doc`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleCopy = (response) => {
    let html = `
      <!DOCTYPE html>
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
      <head><meta charset='utf-8'><title>Document</title></head><body>${response}</body></html>
    `

    const container = document.createElement('div');
    document.body.appendChild(container);
    container.innerHTML = html;

    const textarea = document.createElement('textarea');
    textarea.value = container.innerText;
    document.body.appendChild(textarea);
    textarea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {

    }
    document.body.removeChild(textarea);
    document.body.removeChild(container);
  }

  // useEffect(() => {
  //   // Add the AdSense script to the document
  //   if (!document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
  //     const script = document.createElement('script');
  //     script.async = true;
  //     script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  //     script.crossOrigin = 'anonymous';
  //     script.onload = () => {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     };
  //     console.log("addsense-script",script)
  //     document.head.appendChild(script);
  //   } else {
  //     // If the script is already present, initialize the ad slot
  //     (window.adsbygoogle = window.adsbygoogle || []).push({});
  //   }}, []);

  console.log("window.adsbygoogle", window.adsbygoogle)
  return (
    <div
      style={{
        width: "100%",
        // textAlign: !fileContent && "center",
        // maxWidth: "90%",
        // backgroundColor: !fileContent && "#EAEDED",
        // height: "90%",
        // borderRadius: "20px",
        // marginTop: "10px",
        // marginLeft: "30px",
      }}
    >
      {/* <div style={{height:"100%",backgroundColor:"pink"}}>

      <ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5467337913252726"
         data-ad-slot="8101520442"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>

          </div> */}
      {!fileContent && !isLoading && (
        <>
          {!file && (
            <div className={styles.boxContent}>
              {/* <div className={styles.tryButton}>
                    <a>Try for Free</a>
                    <input
                                    type="file"
                                    accept=".pdf,.doc,.docx"
                                    onChange={handleFileUpload2}
                                    className={styles.fileInput}
                                    id="file-upload"
                                />
                    </div>      */}
              <div>
                <label htmlFor="file-upload" className={styles.fileLabel}>
                  <div className={styles.uploadIcon}></div>
                  <span className={styles.textFile}>SELECT FILE</span>
                  <span>Add PDFS, WORD files</span>
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileUpload2}
                    className={styles.fileInput}
                    id="file-upload"
                  />
                  <div style={{ fontSize: "12px", color: "#555", display: "flex", justifyContent: "center", gap: "5px" }} >
                    Supported format: <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }} >PDF</div>
                    <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }} >Docx</div>
                  </div>
                </label>

              </div>
            </div>
          )}
          {/* <input
            type="file"
            id="fileInput"
            accept=".pdf, .doc, .docx"
            style={{ display: "none" }}
            onChange={handleFileUpload2}
          /> */}
        </>

      )}
      {file && !isLoading && !response && (
        <div
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            borderRadius: "5px",
            backgroundColor: "#e0e0e0",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <strong>Selected File:</strong> {file.name}
        </div>
      )}

      {response && (
        // <div
        //   style={{
        //     marginTop: "20px",
        //     padding: "10px",
        //     border: "1px solid #ddd",
        //     borderRadius: "5px",
        //     backgroundColor: "#fff",
        //     overflow: "auto",
        //     maxHeight: "100%",
        //   }}
        // >
        //   {response}
        // </div>

        <div

          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            backgroundColor: "#fff",
            overflowY: "scroll",
            maxHeight: "55vh",
          }}
          ref={inputRef}
          dangerouslySetInnerHTML={{ __html: response }}
        />
      )}

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "90%",
          }}
        >
          <img src={GearGif} alt="Loading..." style={{ width: "160px", height: "160px" }} />
          <div>Processing File...</div>
        </div>
      ) : (
        <div style={{ padding: "20px", overflowY: "auto" }}>
          {response && (
            <>
              <Box
                sx={{
                  position: "relative",
                  bottom: 5,
                  left: { xs: "5%", md: "70%" },
                  display: "flex",
                  gap: "5px",
                  width: { xs: "90%", md: "20%" },
                  borderRadius: "12px",
                  backgroundColor: "#B2BABB",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <ShareLinkComponent response={response} fileName={fileName} />

                <Tooltip title="Copy to Clipboard">
                  <IconButton onClick={() => handleCopy(response)}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Download">
                  <IconButton onClick={() => handleDownloadDOC(response)}>
                    <DownloadOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {/* <Menu
                  anchorEl={anchorEl}
                  open={dropdown}
                  onClose={handleCloseMenu}

                >
                  <MenuItem onClick={handleDownloadPDF}>Download .pdf</MenuItem>
                  <MenuItem onClick={() => handleDownloadDOC(response )}>
                    Download .doc
                  </MenuItem>
                </Menu> */}
              </Box>
            </>
          )}
        </div>
      )}

      {errorMessage && (
        <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
      )}
    </div>
  );
}

export default FileUploadComponent;
