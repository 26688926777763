import React, { useRef, useState } from "react";
import FileUploadComponent from "./FileUploadComponent";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation, useHistory } from "react-router-dom";

import "./AdvancedSummary.css";
import {
  Card,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Gear from "../../../Gear.png";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import generateIcon from "../../../assets/generateIcon.svg";
import Navbar from "../../Navbar/Navbar";
import Chatbot from "./Chatbot";

const Tab = styled(BaseTab)`
  color: black;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: transparent;
  width: 100%;
  text-wrap: nowrap;
  padding: 8px 8px;
  margin: 6px;
  border-radius: 20px;
  display: flex;
  justify-content: center;

  &.${tabClasses.selected} {
    background-color: #0066cc;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: white;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 200px;
  border-radius: 20px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `
);

const test = {
  answer:
    "<h2>Professional Summary</h2>\n<ul>\n<li>Detail-oriented professional with 1 year of experience in software testing</li>\n<li>Expertise in various types of testing and testing phases</li>\n</ul>\n\n<h2>Work Experience</h2>\n<ul>\n<li>Worked as Manual Test Engineer at Fino Payments Bank</li>\n<li>Analyzed requirements, designed and executed test cases, reported and tracked defects</li>\n</ul>\n\n<h2>Projects Accomplished</h2>\n<ul>\n<li>Current & Saving Account Opening</li>\n<li>UPI Daily Limit Transaction - P2P & P2M</li>\n<li>Insurance Policy Booking - Shopkeeper Policy Booking & Health Policy Booking</li>\n</ul>\n\n<h2>Certification</h2>\n<ul>\n<li>Certificate in Manual Testing & Automation Testing from Qspider's vashi</li>\n</ul>\n\n<h2>Academic Qualifications</h2>\n<ul>\n<li>B.Sc. (CS) from Pune University in 2022</li>\n<li>H.S.C. from Maharashtra State Board in 2018</li>\n</ul>\n\n<h2>Personal Details</h2>\n<ul>\n<li>Female, Married, DOB: 01 Jan 2000</li>\n<li>Languages Known: English, Hindi, Marathi</li>\n<li>Permanent Address: Pune - 412303</li>\n<li>Current Address: Mumbai - 400071</li>\n<li>Nationality: Indian</li>\n</ul>",
};

function AdvancedSummary({
  setWordsCount,
  wordsCount,
  uploadError,
  setuploadError,
  showSummaryChatbot,
  setPdfUrls,
  chat,
  setChat,
  isSelected,
  setIsSelected,
  ocrProcessing,
  setOcrProcessing,
  isLoading,
  setIsLoading,
  response,
  setResponse,
  handleReset,
  urlStats,
  setUrlStats,
  isAuthenticated,
  setIsAuthenticated,
  chatHistory,
  setChatHistory,
  file,
  setFile,
  caseName,
  setShowSummaryChatbot,
  fileContent,
  setFileContent,
  fileName,
  setFileName,
}) {
  const [userEmail, setUserEmail] = useState();
  const [type1Value, setType1Value] = useState("topic");
  const [type2Value, setType2Value] = useState("small");
  const [uploadedFile, setUploadedfile] = useState();
  const [newCase, setNewCase] = useState();
  const [useNewCase, setUseNewCase] = useState();

  const [selectedModel, setSelectedModel] = useState("good");

  const [openModal, setOpenModal] = useState(false);
  const [caseSelected, setCaseSelected] = useState(false);
  const { state } = useLocation();

  useEffect(()=>{
    console.log(wordsCount);
    if(wordsCount>1000){
      setuploadError(
        "This document length exceeds the free account limit."
      );
    }
  },[wordsCount])

  useEffect(() => {
    console.log("fileContent22", fileContent);
    console.log("state", state?.parsedResponse);
    setFileContent(state?.parsedResponse);
    console.log(urlStats, "urlstats");

    // if (urlStats != "home"){
    //   console.log("handleReset runs")
    //   handleReset()
    // }
  }, [state?.parsedResponse]);

  const handleType1Change = (event, newValue) => {
    setType1Value(newValue);
  };

  const handleType2Change = (event, newValue) => {
    setType2Value(newValue);
  };

  const handleSummaryClick = () => {
    setIsSelected(!isSelected);
  };

  const buttonStyle3 = (isSelectedModel) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px 15px",
    borderRadius: "2em",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    border: isSelectedModel ? "none" : "1px solid rgb(20, 114, 255)",
    color: isSelectedModel ? "#fff" : "rgb(20, 114, 255)",
    backgroundColor: isSelectedModel ? "rgb(20, 114, 255)" : "#fff",
  });
  const buttonStyle4 = (isSelectedModel) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px 15px",
    borderRadius: "2em",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    border: isSelectedModel ? "none" : "1px solid rgb(20, 114, 255)",
    color: isSelectedModel ? "#fff" : "rgb(20, 114, 255)",
    backgroundColor: isSelectedModel ? "rgb(20, 114, 255)" : "#fff",
  });

  const buttonStyle = {
    color: isSelected ? "white" : "black",
    cursor: "pointer",
    fontSize: "0.875rem",
    fontWeight: 400,
    backgroundColor: isSelected ? "#0066cc" : "transparent",
    width: "100%",
    height: "30%",
    marginTop: "10px",
    border: "1px solid black",
    whiteSpace: "nowrap",
    padding: "8px 8px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    textAlign: "center",
    bgcolor: "background.paper",

    p: 4,
  };

  const ModalComponent = () => {
    return (
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-description" variant="h5">
            Process Finished
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            You will be further noitified by email.
          </Typography>
          <Button
            onClick={() => setOpenModal(false)}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  const callOverviewOnce = async (content) => {
    setIsLoading(true);

    const apiLink = "https://pdfing.ai:7000/parse";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        content: content,
        category: "overview",
        length: "",
        model: selectedModel,
      }),
    };

    try {
      const response = await fetch(apiLink, requestOptions);
      const parsedResponse = await response.json();
      setResponse(parsedResponse.answer);
      setuploadError("");

      console.log("API response---", parsedResponse);
      setIsLoading(false);
      setIsSelected(false);
      // document.body.classList.toggle("show-chatbot");
      setShowSummaryChatbot(true);
    } catch (error) {
      handleReset();
      setWordsCount(0);
      if (error.message === "Failed to fetch") {
        setuploadError(
          "Internet connection is lost. Please check your network."
        );
      } else {
        setuploadError("An unexpected error occurred.");
      }

      console.log("ERROR:", error);
      return;
      setIsLoading(false);
    }
  };

  const handleGenerate = async () => {
    if (!file) {
      return;
    }
    setIsLoading(true);
    setResponse("");

    const apiLink = "https://pdfing.ai:7000/parse";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        content: fileContent,
        category: isSelected ? "overview" : type1Value,
        length: isSelected ? "" : type2Value,
        // page_count:"45",
        model: selectedModel,
      }),
    };

    try {
      const response = await fetch(apiLink, requestOptions);
      const parsedResponse = await response.json();
      setResponse(parsedResponse.answer);

      console.log("API response---", parsedResponse);
      setIsLoading(false);
      setIsSelected(false);
    } catch (error) {
      console.log("ERROR:", error);
      setIsLoading(false);
    }
  };

  const handleIngest = async () => {
    setIsLoading(true);
    setResponse("");

    const apiLink = "https://upload.verisage.ai:5000/ingest";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        bucket_name: "parvbucket12",
        email: userEmail,
        case_name: useNewCase ? newCase : caseName,
        text: fileContent,
      }),
    };

    try {
      const response = await fetch(apiLink, requestOptions);
      const parsedResponse = await response.json();
      setResponse(parsedResponse.answer);

      console.log("API response---", parsedResponse);
      setIsLoading(false);
      setIsSelected(false);
      setOpenModal(true);
    } catch (error) {
      console.log("ERROR:", error);
      setIsLoading(false);
    }
  };

  const uploadCase = async (caseN) => {
    setCaseSelected(true);

    const formData = new FormData();
    formData.append("casename", caseN);
    formData.append("email", userEmail);
    formData.append("files", uploadedFile);

    try {
      const response = await axios.post(
        "https://upload.verisage.ai:5000/upload",
        formData
      );
      console.log(`files uploaded:`, response.data.files_uploaded);
    } catch (error) {
      console.error(`Error uploading files:`, error.response.data);
    }
  };

  useEffect(() => {
    let email = localStorage.getItem("email");
    setUserEmail(email);

    // if (urlStats !== "home"){
    //   handleReset()
    // }
    return () => {
      handleReset();
      // const wordsArray =fileContent.trim().split(/\s+/);

      // setWordsArray(wordsArray.length)

      // console.log("exitsomethi444",fileContent)
    };
  }, []);
  const data = "Get premium";

  return (
    console.log("response--", response),
    (
      <>
        <Chatbot
          chatHistory={chatHistory}
          setChatHistory={setChatHistory}
          showSummaryChatbot={showSummaryChatbot}
          fileName={fileName}
          fileContent={fileContent}
          setPdfUrls={setPdfUrls}
          chat={chat}
          setChat={setChat}
        />
        <Navbar
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          data={data}
          file={file}
          setFile={setFile}
        />
        <div style={{ minHeight: "90vh", paddingTop: "50px" }}>
          <div
            style={{
              fontSize: "28px",
              fontWeight: "bolder",
              textAlign: "center",
              paddingTop: "5px",
            }}
          >
            {/* File Summarization Tool */}
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              padding: "10px",
              // height: "100%",
              backgroundColor: "white",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;",
              borderRadius: "20px",
              margin: "10px",
            }}
          >
            <div
              style={{
                flex: 0.6,
                height: "100%",
                width: "100%",
                // borderRight: "1px solid #CCD1D1",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  height: "8%",
                  width: "90%",
                  fontSize: "18px",
                  marginLeft: "30px",
                  paddingTop: "10px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  display: "flex",
                  fontWeight: "bold",
                }}
              >
                {/* {response? "Summarized Text" :"File Summarization Tool"} */}
                <div></div>
                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(20, 114, 255)", color: "#fff", padding: "3px 15px", borderRadius: "2em", fontSize: "17px", fontWeight: "bold", cursor: "pointer" }}>Get Premium</div> */}
              </div>

              <div style={{ height: "60vh", width: "100%",  display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FileUploadComponent
                  response={response}
                  type1Value={type1Value}
                  type2Value={type2Value}
                  setWordsCount={setWordsCount}
                  urlStats={urlStats}
                  fileContent={fileContent}
                  fileName={fileName}
                  file={file}
                  setFile={setFile}
                  setShowSummaryChatbot={setShowSummaryChatbot}
                  setFileName={setFileName}
                  setFileContent={setFileContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  ocrProcessing={ocrProcessing}
                  setUploadedfile={setUploadedfile}
                  callOverviewOnce={callOverviewOnce}
                />
              </div>

              <div style={{
                    display: "flex",
                    flexDirection: "column"
                  }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "2em",
                  }}
                >
                  <div style={{ color: wordsCount > 1000 ? "red" : "" }}>
                    Word Count: {wordsCount}/1000
                  </div>
                  {uploadError != "" && (
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        fontSize: "18px",
                      }}
                    >
                      {uploadError}
                    </div>
                  )}
                  <div></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "10%",
                    marginTop: "10px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    paddingLeft: "30px",
                  }}
                >
                  <Tooltip
                    title={
                      "You can choose the type of document summary you want here."
                    }
                  >
                    <InfoIcon
                      fontSize="small"
                      sx={{ color: "#0066cc", marginRight: "5px" }}
                    />
                  </Tooltip>
                  Summary Type
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1em 1em",
                    paddingLeft: "3em",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={buttonStyle4(type1Value === "topic")}
                        onClick={() => setType1Value("topic")}
                      >
                        TOPIC
                      </div>
                      <div
                        style={buttonStyle4(type1Value === "page")}
                        onClick={() => setType1Value("page")}
                      >
                        PAGE
                      </div>
                    </div>

                    {/* Second set of buttons */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      <div
                        style={buttonStyle4(type2Value === "small")}
                        onClick={() => setType2Value("small")}
                      >
                        SHORT
                      </div>
                      <div
                        style={buttonStyle4(type2Value === "long")}
                        onClick={() => setType2Value("long")}
                      >
                        LONG
                      </div>
                    </div>
                    {/* <Box
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    marginLeft: "30px",
                    backgroundColor: "#D7DBDD",
                    width: "fit-content",
                    borderRadius: "40px",
                    paddingX: "10px",
                  }}
                >
                  <Tabs onChange={handleType1Change} defaultValue={"topic"}>
                    <TabsList>
                      <Tab disabled={!fileContent} value={"topic"}>
                        Topic
                      </Tab>
                      <Tab disabled={!fileContent} value={"page"}>
                        Page
                      </Tab>
                    </TabsList>
                  </Tabs>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    marginTop: "5px",
                    marginLeft: "30px",
                    backgroundColor: "#D7DBDD",
                    width: "fit-content",
                    borderRadius: "40px",
                    paddingX: "10px",
                  }}
                >
                  <Tabs onChange={handleType2Change} defaultValue={"small"}>
                    <TabsList>
                      <Tab disabled={!fileContent} value={"small"}>
                        Short
                      </Tab>
                      <Tab disabled={!fileContent} value={"long"}>
                        Long
                      </Tab>
                    </TabsList>
                  </Tabs>
                </Box> */}
                  </div>
                  <div>
                    <div style={{ fontSize: "14px", marginTop: "20px" }}>
                      <Tooltip
                        title={"This is the ocr button this will help you"}
                      >
                        <InfoIcon
                          fontSize="small"
                          sx={{ color: "#0066cc", marginRight: "5px" }}
                        />
                      </Tooltip>
                      Document requires OCR Processing
                      <Switch
                        checked={ocrProcessing}
                        onChange={() => setOcrProcessing(!ocrProcessing)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        paddingTop: "6px",
                      }}
                    >
                      <div
                        style={buttonStyle3(selectedModel === "good")}
                        onClick={() => setSelectedModel("good")}
                      >
                        GOOD
                      </div>
                      <div
                        style={buttonStyle3(selectedModel === "better")}
                        onClick={() => setSelectedModel("better")}
                      >
                        BETTER
                      </div>
                      <div
                        style={buttonStyle3(selectedModel === "best")}
                        onClick={() => setSelectedModel("best")}
                      >
                        BEST
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 1em",
                    paddingLeft: "3em",
                    width: "38%",
                  }}
                >
                  <div
                    onClick={handleReset}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid rgb(20, 114, 255)",
                      color: "rgb(20, 114, 255)",
                      padding: "0px 22px",
                      borderRadius: "2em",
                      fontSize: "17px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    RESET
                  </div>
                  <div></div>
                  <div className="generateContainer">
                    <p
                      onClick={handleGenerate}
                      style={{
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "17px",
                        position: "absolute",
                        bottom: "15%",
                        left: "20px",
                      }}
                    >
                      GENERATE
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ModalComponent />

            <div style={{ flex: 0.4 }}>
              {/* {(response || isLoading) && (
            <div
              style={{
                borderBottom: "1px solid #B2BABB",
                height: "8%",
                width: "90%",
                fontSize: "22px",
                marginLeft: "30px",
                paddingTop: "10px",
                fontWeight: "bold",
              }}
            >
              Summarized Text
            </div>
          )} */}
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default AdvancedSummary;
