import { useEffect, useState } from 'react';

import HeroSection from '../HeroSection/HeroSection';
import Navbar from '../Navbar/Navbar';
import FeatureSection from './FeatureSection/FeatureSection';
import HeadlineSection1 from './HeadlineSection/HeadlineSection1';
import HeadlineSection2 from './HeadlineSection/HeadlineSection2';
import styles from './Home.module.css'
import SummaryContainer from './SummaryContainer/SummaryContainer';
import { Testimonials } from '../Testmonials/Testimonials';
import JsonData from "../../data/data.json";

function Home({handleReset,urlStats,setUrlStats,isAuthenticated,setIsAuthenticated,file, setFile}){
    const data = "Try for free"
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return(
        <>
        <Navbar  data={data} file={file} setFile={setFile}/>
        <div className={styles.mainHome}>
            <div className={styles.summarContainer}>
            {/* <span>
        <GoogleLogin
          onSuccess={credentialResponse => {
            const decoded = jwtDecode(credentialResponse?.credential);
            console.log("decode33",decoded);
            
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </span> */}
                <SummaryContainer file={file} setFile={setFile}/>
            </div>
            
            <HeroSection/>
            <div className={styles.headlineContainer}>
                <div className={styles.headlineSpace}>
                    <div>
                        <HeadlineSection1 />
                    </div>
                    <div>
                        <HeadlineSection2 />
                    </div>
                </div>
                {/* <div className={styles.partnersTage}>
                    <h4 className={styles.partnersText}>Partners</h4>
                </div>
                <div className={styles.partnerContainer}>
                    <div className={styles.eachContainer}>Seismic</div>
                    <div className={styles.eachContainer}>Arieal</div>
                    <div className={styles.eachContainer}>SIEMENS</div>
                    <div className={styles.eachContainer}>Tengent</div>
                    <div className={styles.eachContainer}>Steam</div>
                    <div className={styles.eachContainer}>Phoenix</div>
                </div> */}
            </div>
            
            <Testimonials data={landingPageData.Testimonials} />
            <FeatureSection />
        </div>
        </>
    )
}

export default Home;